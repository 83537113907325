<template>
<div class="dialog-image-component">
  <div class="image-container" @click="showDialog">
    <el-image
        class="el-upload-list__item-thumbnail"
        :src="`${host + getImageFromProps}`"
        alt=""
        fit="scale-down">
    </el-image>
  </div>
  <el-dialog width="28.75rem" class="fingerprint-image-dialog" :title="userName" :visible.sync="showDialogWindow" @close="closeDialog">
      <el-row class="gallery-section" justify="center" align="middle">
          <el-col :lg="24" :md=24 :sm="24" :xs="24">
            <div v-if="getImageFromProps!== null && getImageFromProps !== ''">
                  <el-image
                    class="el-upload-list__item-thumbnail"
                    style="width: auto; height: 18rem"
                    :src="`${host + getImageFromProps}`"
                    alt=""
                    fit="scale-down">
                  </el-image>
                </div>
                <template v-else>
                  <div class="icon-empty-image" align="center">
                    <el-image style="width: 100%; height: 45%;" alt="No hay imagen seleccionada">
                      <div slot="error" class="image-slot">
                        <i style="font-size: 3.8em;" class="el-icon-warning-outline"></i>
                      </div>
                    </el-image>
                    <p>No hay Imagen</p>
                  </div>
                </template>
          </el-col>
          <el-col :lg="24" :md=24 :sm="24" :xs="24">
            <el-row justify="center" align="middle" style="margin:0;">
              <el-col :span="24">
                      <div class="image-description">
                        <ul>
                          <li>Fecha de Subida:
                            <el-tag size="small" type="success">{{ setCreatedDate | formatDate }}</el-tag>
                          </li>
                        </ul>
                      </div>
              </el-col>
            </el-row>
          </el-col>
      </el-row>
    </el-dialog>
</div>
</template>
<script>
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi.slice(0, conf.baseApi.indexOf('api-manager'))
export default {
  name: 'DialogImage',
  props: {
    setImage: {
      default: null,
      type: String,
      required: false
    },
    idUserName: {
      type: String,
      required: false
    },
    nameCallback: {
      type: Function,
      required: false
    },
    setCreatedDate: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      host: urlServer,
      imageLoaded: null,
      showDialogWindow: false,
      userName: ''
    }
  },
  computed: {
    getImageFromProps () {
      let image = null
      if (this.setImage !== null && this.setImage !== '') {
        image = this.setImage
      } else {
        image = ''
      }
      return image
    }
  },
  methods: {
    showDialog () {
      this.getAsyncName(this.idUserName)
      this.showDialogWindow = true
      console.log(this.showDialogWindow)
    },
    closeDialog () {
      console.log('se cierra ' + this.showDialogWindow)
    },
    /**
     *
     * @param {String} idUser Receives an User ID to search its name
     */
    async getAsyncName (idUser) {
      this.userName = await this.nameCallback(idUser)
    }
  }
}
</script>

<style lang="scss" scoped>
.image-container{
  cursor: pointer;
  .el-image{
    width: auto;
    height: 3.4rem
  }
}
.image-description{
  padding-top: 0;
  ul{
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    li{
      line-height: 1.6em;
      text-decoration: none;
    }
  }
}
</style>
