import request from '@/utils/request-biometric'
export function search (params) {
  return request({
    url: '/bio-fingerprint/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/bio-fingerprint/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function getFingerprint (id) {
  return request({
    url: `/bio-fingerprint/${id}`,
    method: 'get'
  })
}

export function deleteFingerprint (id) {
  return request({
    url: `/bio-fingerprint/${id}`,
    method: 'delete'
  })
}

export function exportExcel (data) {
  return request({
    url: 'bio-fingerprint/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
